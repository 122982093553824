import { Maybe } from '@/common/types';
import { Input } from '@chakra-ui/react';
import { ChangeEvent, FC } from 'react';

type CustomFieldDatetimeProps = {
  onChange: (input: { customFieldId: number; value: string }) => void;
  value: Maybe<string>;
  customFieldId: number;
  disabled?: boolean;
};

const CustomFieldDatetime: FC<CustomFieldDatetimeProps> = (props: CustomFieldDatetimeProps) => {
  const { onChange, value, customFieldId, disabled = false } = props;

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({
      customFieldId,
      value: event.target.value,
    });
  };

  return (
    <Input type='datetime-local' value={value} onChange={onInputChange} isDisabled={disabled} />
  );
};

export default CustomFieldDatetime;
