import { Maybe } from '@/common/types';
import { Input } from '@chakra-ui/react';
import { ChangeEvent, FC, useState } from 'react';

type CustomFieldDateProps = {
  onChange: (input: { customFieldId: number; value: string }) => void;
  value: Maybe<string>;
  customFieldId: number;
  disabled?: boolean;
};

const CustomFieldDate: FC<CustomFieldDateProps> = (props: CustomFieldDateProps) => {
  const { onChange, value, customFieldId, disabled = false } = props;

  const [localValue, setLocalValue] = useState<string | undefined>(value);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
    onChange({
      customFieldId,
      value: event.target.value,
    });
  };

  return <Input type='date' value={localValue} onChange={onInputChange} isDisabled={disabled} />;
};

export default CustomFieldDate;
