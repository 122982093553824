import { Maybe } from '@/common/types';
import type { ICustomFieldFloatConfig } from '@/modules/workOrders/types/workOrderCustomField';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import type { FC } from 'react';

type CustomFieldFloatProps = {
  onChange: (input: { customFieldId: number; value: number }) => void;
  handleError?: (customFieldId: number, errorMessage: string) => void;
  value: Maybe<number>;
  customFieldId: number;
  disabled?: boolean;
  customFieldNumberConfig?: ICustomFieldFloatConfig;
};

const defaultStep = 0.1;

const CustomFieldFloat: FC<CustomFieldFloatProps> = (props: CustomFieldFloatProps) => {
  const {
    onChange,
    value,
    customFieldId,
    disabled = false,
    customFieldNumberConfig,
    handleError,
  } = props;

  const { t } = useTranslation();

  const { min, max, step } = useMemo(() => {
    if (!customFieldNumberConfig) return { min: undefined, max: undefined, step: defaultStep };
    const { min, max, interval } = customFieldNumberConfig;
    return {
      min: min === null ? undefined : min,
      max: max === null ? undefined : max,
      step: interval === null ? defaultStep : interval,
    };
  }, [customFieldNumberConfig]);

  const handleErrorMessage = useCallback(
    (value: number) => {
      if (max === undefined && min === undefined) return;

      if (!handleError) throw new Error('handleError is required');

      if (min !== undefined && value < min) {
        handleError(
          customFieldId,
          t('form.validation.range-violation-message', { minValue: min, maxValue: max })
        );
        return;
      }

      if (max !== undefined && max < value) {
        handleError(
          customFieldId,
          t('form.validation.range-violation-message', { minValue: min, maxValue: max })
        );
        return;
      }

      handleError(customFieldId, '');
    },
    [customFieldId, handleError, max, min, t]
  );

  const onNumberInputChange = useCallback(
    (valueString: string) => {
      const value = Number(valueString);
      handleErrorMessage(value);
      onChange({ customFieldId, value: Number(valueString) });
    },
    [handleErrorMessage, onChange, customFieldId]
  );

  return (
    <NumberInput
      defaultValue={value}
      onChange={onNumberInputChange}
      isDisabled={disabled}
      min={min}
      max={max}
      step={step}
    >
      {/* patternはNumberInputのデフォルト値 */}
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

export default CustomFieldFloat;
