import { Maybe } from '@/common/types';
import type { ICustomFieldTextConfig } from '@/modules/workOrders/types/workOrderCustomField';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button, Input, Textarea } from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import { BsStars } from 'react-icons/bs';

type CustomFieldTextProps = {
  onChange: (input: { customFieldId: number; value: string }) => void;
  value: Maybe<string>;
  customFieldId: number;
  onCreateTextSuggest?: (text: string) => Promise<string | false>;
  suggestible?: boolean;
  disabled?: boolean;
  customFieldTextConfig?: ICustomFieldTextConfig;
};

const DEFAULT_TEXTAREA_ROWS = 5;

const CustomFieldText: FC<CustomFieldTextProps> = (props: CustomFieldTextProps) => {
  const {
    onChange,
    value,
    customFieldId,
    onCreateTextSuggest,
    suggestible = false,
    disabled = false,
    customFieldTextConfig,
  } = props;

  const { t } = useTranslation();

  const onSuggestButtonClick = useCallback(async () => {
    if (onCreateTextSuggest === undefined) throw new Error('onCreateTextSuggest is undefined');
    const result = await onCreateTextSuggest(value || '');
    if (!result) return;
    onChange({ customFieldId, value: result });
  }, [customFieldId, onChange, onCreateTextSuggest, value]);

  const rows = useMemo(
    () => customFieldTextConfig?.rows || DEFAULT_TEXTAREA_ROWS,
    [customFieldTextConfig]
  );

  return (
    <>
      {rows === 1 ? (
        <Input
          color='neutral.800'
          value={value}
          onChange={(e) =>
            onChange({
              customFieldId,
              value: e.target.value,
            })
          }
          isDisabled={disabled}
        />
      ) : (
        <Textarea
          color='neutral.800'
          value={value}
          onChange={(e) =>
            onChange({
              customFieldId,
              value: e.target.value,
            })
          }
          rows={rows}
          isDisabled={disabled}
        />
      )}
      {suggestible && (
        <Button
          mt={1}
          size='sm'
          leftIcon={<BsStars />}
          colorScheme='primary'
          variant='outline'
          isDisabled={!value}
          onClick={onSuggestButtonClick}
        >
          {t('AI.suggest')}
        </Button>
      )}
    </>
  );
};

export default CustomFieldText;
